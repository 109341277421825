@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #00003f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes blobAnimation {
  0% {
    translate: 40% -25%;
    border-radius: 60% 40% 30% 70% / 100% 85% 92% 74%;
  }
  50% {
    translate: 0% 13%;
    border-radius: 20% 71% 47% 70% / 81% 15% 22% 54%;
    rotate: 41deg;
    scale: 1.15;
  }
  100% {
    translate: -45% 39%;
    border-radius: 100% 75% 92% 74% / 60% 80% 30% 70%;
    rotate: -60deg;
    scale: 1.05;
  }
}

.animated-gradient-bg {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blob {
  position: absolute;
  width: 150%;
  height: 150%;
  opacity: 0.7;
  animation: blobAnimation 20s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite alternate;
  filter: blur(45px);
  background: linear-gradient(
    47deg,
    rgba(255, 69, 0, 1) 20%,
    rgba(255, 0, 132, 1) 40%,
    rgba(0, 255, 150, 1) 60%,
    rgba(0, 150, 255, 1) 80%
  );
  z-index: -1;
}